import React from 'react';
import { graphql } from "gatsby";

import Layout from '../layouts/Layout/Layout';
import TextBlock from '../components/TextBlock/TextBlock';
import PostPreviewAlt from '../components/PostPreview/PostPreviewAlt';
import SocialsImage from '../images/socials-image.jpg';

const tags = (props) => {
    const { edges, totalCount } = props.data.allMarkdownRemark;
    const { tag } = props.pageContext;
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
      } tagged with "${tag}"`

    return (

        <Layout
            title='Front-End Chicken - adventures in front-end code'
            description='Blog about learning to become a front-end developer.'
            url={'https://www.frontendstumbles.com' + props.location.pathname.slice(0,-1)}
            image={SocialsImage}
            type='website'
        >
        <TextBlock heading={tagHeader} />
        
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title, date, image } = node.frontmatter
          const modifiedExcerpt = node.excerpt.replace(/\.([^\s\d])/g, '. $1')
          return (
            <PostPreviewAlt
                key={slug}
                heading={title}
                date={date}
                description={modifiedExcerpt}
                path={slug}
                image={image.childImageSharp.fluid}
      />
          )
        })}

        </Layout>
    )
}


export default tags;

export const pageQuery = graphql`
query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            date(formatString: "DD, MMMM, YYYY")
            title
            description
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
  }
`