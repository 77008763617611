import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextBlockStyled = styled.div`
    margin-bottom: 20px;
    padding-right: 20px;
`;

const Heading = styled.h1`
    font-size: 2.8em;
    font-weight: 700;
    color: ${props => props.theme.colors.primaryAccent};

    @media ${props => props.theme.media.mobileL} {
        font-size: 2em;
    }
`;


const textBlock = (props) => {
    return (
        <TextBlockStyled>
            <Heading>{props.heading}</Heading>
            {props.children}
        </TextBlockStyled>
    )
}

textBlock.propTypes = {
    heading: PropTypes.string.isRequired,
    children: PropTypes.any,
}


export default textBlock;