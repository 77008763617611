import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import MinorHeading from '../UI/MinorHeading/MinorHeading';

const PostPreviewAltStyled = styled.div`
    display: flex;
    width: 100%;
    margin: 20px;
    align-items: center;

    
    @media ${props => props.theme.media.mobileL} {
        flex-direction: column;
    }
`;

const ImageStyled = styled(Img)`
    height: 200px;
    width: 200px;
    object-fit: cover;

    @media ${props => props.theme.media.mobileL} {
        width: 100%;
        padding-bottom: 100%;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

const Date = styled.small`
    font-size: 0.8em;
`;

const Description = styled.p`
    padding: 5px 0;
`;

const ReadMore = styled(Link)`
    padding: 5px 0;
    font-weight: 700;
`;

const ImageWrapper = styled(Link)`
    padding: 10px;
    @media ${props => props.theme.media.mobileL} {
        width: 100%;
    }
`;




const postPreviewAlt = (props) => {
    return (
        <PostPreviewAltStyled>
            <ImageWrapper to={props.path}>
                <ImageStyled fluid={props.image} />
            </ImageWrapper>
            <InfoWrapper>
            <Link to={props.path}><MinorHeading textColor={props => props.theme.colors.secondaryAccent}>{props.heading}</MinorHeading></Link>
                <Date>{props.date}</Date>
                <Description>{props.description}...</Description>
                <ReadMore to={props.path}>Read more →</ReadMore>
            </InfoWrapper>
        </PostPreviewAltStyled>
    )
}

postPreviewAlt.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
}


export default postPreviewAlt;